import { createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"
import { fetchOrganizationUsers } from "../organization/thunk"
import { User } from "./types"
import { fetchDevices } from "../device/thunk"
import { fetchAdminUsers } from "../admin/thunk"

const usersAdapter = createEntityAdapter<User>()

export const slice = createSlice({
    name: 'users',
    initialState: {
        entities: {}, 
        ids: [],
        error: '',
        isLoading: true,
        count: 15
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchOrganizationUsers.fulfilled, (state, action) => {
            usersAdapter.upsertMany(state, action.payload.users)
            state.isLoading = false
        })
        builder.addCase(fetchOrganizationUsers.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchAdminUsers.fulfilled, (state, action) => {
            usersAdapter.upsertMany(state, action.payload[0].users)
            state.count = action.payload[1]
            state.isLoading = false
        })
        builder.addCase(fetchAdminUsers.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchDevices.fulfilled, (state, action) => {
            usersAdapter.upsertMany(state, action.payload.users)
            state.isLoading = false
        })
        builder.addCase(fetchDevices.pending, (state) => {
            state.isLoading = true
        })
    }
})

const reducer = slice.reducer
export default reducer

export const {
    selectById: selectUserById,
    selectIds: selectUserIds,
    selectEntities: selectUserEntities,
    selectAll: selectAllUsers,
    selectTotal: selectTotalUsers
} = usersAdapter.getSelectors((state: RootState) => state.users)
