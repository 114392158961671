import { AbilityBuilder, Ability, AbilityClass } from "@casl/ability"
import { AUTH_CONFIG } from "../auth0-variables"

type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete';
type Subjects = 'Device' | 'Organization' | 'User' | 'all';
export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>
export type User = any;

const defineRulesFor = (roles?: string): any => {
    const { can, rules } = new AbilityBuilder<AppAbility>(Ability)

    switch (roles) {
        case 'admin':
            can('manage', 'all')
            break
        case 'reseller':
            can('manage', 'Organization')
            can('create', 'Device')
            can('update', 'Device')
            break
        case 'user':
            can('read', 'User')
            can('manage', 'Device')
            break
        default:
            can('read', 'User')
            break
    }

    return rules
}

const buildAbilityFor = (user: User): AppAbility => {
    if (user === undefined) {
        return new AppAbility(defineRulesFor("user"))
    }
    if (user[AUTH_CONFIG.roleUrl] === undefined) {
        return new AppAbility(defineRulesFor("user"))
    }
    const role = user[AUTH_CONFIG.roleUrl][0]
    return new AppAbility(defineRulesFor(role))
}

export { buildAbilityFor }