import { createSelector } from "@reduxjs/toolkit"
import { RootState } from "../store"
import { selectUserById } from "../user/slice"
import { selectAllDevices } from "./slice"

// eslint-disable-next-line
const selectDevicesByUserId = (userId: string) => {
    return createSelector(
        [
            (state: RootState) => selectUserById(state, userId),
            (state: RootState) => selectAllDevices(state)
        ],
        (user, devices) => {
            if (user === undefined || user["devices"] === undefined) {
                return []
            }
            return Object.keys(devices)
                .map((d: any) => devices[d])
                // @ts-ignore
                .filter(device => user.devices.includes(device.id))
        }
    )
}

export { selectDevicesByUserId }
