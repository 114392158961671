import React, { ReactElement } from "react"
import { Layout } from "src/components/layout/Layout"
import styled from "styled-components"

const StyledLink = styled.a`
    font-weight: 900;
`

const Support = (): ReactElement => {
    return (
        <Layout
            headerName={"Contact us"}
        >
            <p>All our products are delivered through resellers.</p>
            <p>Would you like to know where to find a PortaDial reseller in your area? Or are you a reseller and would you like more information about our products?</p>
            Check out our <StyledLink href="https://www.portadial.nl/contact">website</StyledLink> or contact us on 0486 – 47 88 99.
        </Layout>
    )
}

export { Support }
