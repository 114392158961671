import { Button } from "antd"
import { ReactElement } from "react"

const Welcome = (): ReactElement => {
    function reloadPage() {
        window.location.href = window.location.origin
      }

    return (
        <>
            Welcome to PortaDial Connect! If you see this screen probably something went wrong please try to refresh the page!
            <br/>
            <br/>
            <Button onClick={() => reloadPage()}>Retry!</Button>
        </>
    )
}

export { Welcome }