import { ReactElement, useEffect } from "react"
import { useSelector } from "react-redux"
import { Layout } from '../../components/layout/Layout'
import moment from "moment"
import { fetchOrganizationUsers } from "../../api/organization/thunk"
import { Table, TableProps } from "antd"
import { useAppDispatch, RootState } from "../../api/store"
import { selectUsersByOrganizationId } from "../../api/organization/selector"
import { useTranslation } from "react-i18next"
import { useAuth0 } from "@auth0/auth0-react"
import { Spinner } from "src/components/Spinner"
import styled from "styled-components"

const StyledTable: any = styled(Table)`
  .ant-table-row:hover {
    cursor: pointer;
  }
`

const Users = (): ReactElement => {
    const dispatch = useAppDispatch()
    const settings = useSelector((state: RootState) => state.settings.data)
    const users = useSelector(selectUsersByOrganizationId(settings.organizations[0]))
    const isLoading = useSelector(
        (state: RootState) => state.organizations.isLoading
    )
    const [t, i18n] = useTranslation('organizations')
    const { getAccessTokenSilently } = useAuth0()

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (a: any, b: any) => a.id - b.id
        },

        {
            title: 'First Name',
            dataIndex: 'first_name',
            key: 'first_name',
        },
        {
            title: 'Last Name',
            dataIndex: 'last_name',
            key: 'last_name',
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Access Expire Date',
            dataIndex: 'access_expire_date',
            key: 'access_expire_date',
            render: (row: any) => {
                if (row) {
                    return (
                        <>
                            {moment(row * 1000).format('ll')}
                        </>
                    )
                }
            },
        },
        {
            title: 'Registration Date',
            dataIndex: 'registration_date',
            key: 'registration_date',
            render: (row: any) => (
                <>
                    {moment(row * 1000).fromNow()}
                </>
            ),
        }
    ]

    useEffect(() => {
        if (settings.organizations.length === 0) {
            return
        }
        getAccessTokenSilently().then(token => dispatch(fetchOrganizationUsers({ token, id: settings.organizations[0] })))
    }, [settings])

    return (
        <>
            {isLoading
                ? <Spinner />
                : <Layout
                    headerName={t('users')}
                    createParameter="/org/users/add"
                >
                    <StyledTable
                        rowKey="id"
                        dataSource={users}
                        columns={columns}
                        scroll={{ x: 500 }}
                    />
                </Layout>
            }
        </>
    )
}

export { Users }
