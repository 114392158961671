import React, { ReactElement, useEffect, useState } from "react"
import { Button, Input, Modal, Descriptions, List, message, Switch, Tabs } from "antd"
import { RouteComponentProps, useHistory } from "react-router"
import { Layout } from "../../components/layout/Layout"
import { fetchDevice, removeDevice, updateDevice } from "../../api/device/thunk"
import { selectDeviceById } from "../../api/device/slice"
import { RootState, useAppDispatch } from "../../api/store"
import { useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import { TabPaneProps } from "antd/lib/tabs"
import { Spinner } from "../../components/Spinner"
import { useAuth0 } from "@auth0/auth0-react"
import Paragraph from "antd/lib/typography/Paragraph"
import { Device } from "src/api/device/types"
import InputMask from "react-input-mask"
import { InputProps } from "antd/lib/input"

const { TabPane } = Tabs

const StyledInfoBlock = styled.div`
  display: block;
  width: 100%;
`

const StyledProductImage = styled.div`
  img {
      padding: 20px;
      height: 100%;
      width: 350px;
  }
  @media screen and (max-width: 90em) {
    display: none;
  }
`

const StyledMacInput: any = styled(Input)`
  margin-top: 10px;
  margin-bottom: 10px;
  text-transform: uppercase;
`

const StyledProductTab: any = styled(TabPane)`
  display: flex;
`

interface RouteParams {
    id: string
}


const Details = ({ match }: RouteComponentProps<RouteParams>): ReactElement => {

    const dispatch = useAppDispatch()
    const device = useSelector((state: RootState) => selectDeviceById(state, match.params.id))
    const settings = useSelector((state: RootState) => state.settings.data)
    const { getAccessTokenSilently } = useAuth0()
    const history = useHistory()

    const [editableNote, setEditableNote] = useState("")
    const [editableLocation, setEditableLocation] = useState("")
    const [editableSupport, setEditableSupport] = useState(false)
    const [visible, setVisible] = useState(false)
    const [macAddressValue, setMacAddressValue] = useState('')

    useEffect(() => {
        if (settings.id === "") {
            return
        }
        getAccessTokenSilently().then(async token => {
            const request = await dispatch(fetchDevice({ token, id: match.params.id, userId: settings.id }))
            if (fetchDevice.fulfilled.match(request)) {
                const deviceResponse = request.payload.devices
                const device = deviceResponse[Object.keys(deviceResponse)[0]] as Device
                setEditableLocation(device.location ?? "")
                setEditableNote(device.note ?? "")
                setEditableSupport(device.support)
            }
        })

    }, [settings])

    function redirectToDevice() {
        if (device) {
            window.open(`https://${device.mac.split('-').join("")}.tunnel.portadial.nl`, "_blank")
        }
    }

    function removeDeviceAction() {
        if (device) {
            getAccessTokenSilently().then(async token => {
                const request = await dispatch(removeDevice({ token, userId: settings.id, deviceId: device.id, mac: macAddressValue }))
                if (removeDevice.fulfilled.match(request)) {
                    message.success("Device successfully removed")
                    history.push('/devices')
                } else if (removeDevice.rejected.match(request)) {
                    message.error("Invalid mac address provided. Please try again.")
                }
            })
        }
    }

    function renderProductImage() {
        switch (device?.type) {
            case "PortaDial Access":
                return <img alt="logo" src={process.env.PUBLIC_URL + '/AccessL.png'} />
            case "PortaDial Access L":
                return <img alt="logo" src={process.env.PUBLIC_URL + '/AccessL.png'} />
            case "PortaDial Access XL":
                return <img alt="logo" src={process.env.PUBLIC_URL + '/AccessXL.png'} />
            default:
                return <img alt="logo" src={process.env.PUBLIC_URL + '/Elego.jpg'} />
        }
    }

    function saveChanges() {
        getAccessTokenSilently().then(async token => {
            const request = await dispatch(updateDevice({ token, deviceId: match.params.id, userId: settings.id, location: editableLocation, note: editableNote, support: editableSupport }))
            if (updateDevice.fulfilled.match(request)) {
                message.success("Changes saved")
            } else if (updateDevice.rejected.match(request)) {
                if (request.payload) {
                    message.error(request.payload.message)
                }
            }
        })
    }

    return (
        <>
            {device !== undefined ? (
                <Layout headerName={"Details"}>
                    <Modal
                        visible={visible}
                        title={<><FontAwesomeIcon icon="exclamation-triangle" /> Are you sure to delete this device?</>}
                        onOk={removeDeviceAction}
                        onCancel={() => setVisible(false)}
                    >
                        You are about to remove this device from your list. To claim this device again you need to contact PortaDial support.
                        <br />
                        <br />
                        <b>For validation purposes, please enter the mac address of the device. </b>
                        <InputMask mask="**-**-**-**-**-**" value={macAddressValue} onChange={e => setMacAddressValue(e.target.value)}>
                            {
                                () => (
                                    <StyledMacInput
                                        type="text"
                                        name="mac"
                                        size="large">
                                    </StyledMacInput>
                                )
                            }
                        </InputMask>
                    </Modal>
                    <Tabs>
                        <StyledProductTab tab="General" key="general">
                            <StyledProductImage>
                                {renderProductImage()}
                            </StyledProductImage>
                            <StyledInfoBlock>
                                <Descriptions
                                    bordered
                                    extra={
                                        <>
                                            <Button
                                                onClick={() => redirectToDevice()}
                                                type="primary"
                                            >
                                                Manage device
                                            </Button>
                                            <Button
                                                danger
                                                onClick={() => setVisible(true)}
                                                style={{ marginLeft: 10 }}
                                            >
                                                Remove device
                                            </Button>
                                        </>
                                    }>
                                    <Descriptions.Item label="Device Name">{device.name}</Descriptions.Item>
                                    <Descriptions.Item label="MAC Address">{device.mac}</Descriptions.Item>
                                    <Descriptions.Item label="Serial Number">{device.serial_number}</Descriptions.Item>
                                    <Descriptions.Item label="WAN">{device.wan}</Descriptions.Item>
                                    <Descriptions.Item label="LAN">
                                        {device.ip}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Location"><Paragraph editable={{ onChange: setEditableLocation }}>{editableLocation}</Paragraph></Descriptions.Item>
                                    <Descriptions.Item label="Note">
                                        <Paragraph editable={{ onChange: setEditableNote }}>{editableNote}</Paragraph>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Service">
                                        <Switch
                                            checked={editableSupport}
                                            onChange={(checked => {
                                                setEditableSupport(checked)
                                            })}
                                        />
                                    </Descriptions.Item>
                                </Descriptions>
                                <Button
                                    onClick={() => saveChanges()}
                                    style={{ float: 'right', marginTop: '10px' }}
                                >
                                    Save changes
                                </Button>
                            </StyledInfoBlock>
                        </StyledProductTab>
                    </Tabs>
                </Layout>
            ) : (
                <Spinner />
            )}
        </>

    )
}

export { Details }
