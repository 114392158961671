import { schema } from "normalizr"

export interface Device {
    id: any
    name: string
    mac: string
    ip: string
    serial_number: string
    wan: string
    note: string
    location_long: number
    location_lat: number
    location: string
    type: string
    support: boolean
    components: {
        hardware: [
            {
                version: number
                component: string
                updated_at: string
            }
        ]
        software: [
            {
                version: number
                component: string
                updated_at: string
            }
        ]
    }
    tunnel_status: {
        status?: string
        ping_ms: string
    }
}

export const deviceSchema = new schema.Entity('devices', {})
