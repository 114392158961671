import React, { FunctionComponent, ReactElement } from 'react'

interface Props {
  message: string
}

const Error: FunctionComponent<Props> = ({ message }): ReactElement => {
  return (
    <div>
      Oops... {message}
    </div>
  )
}

export { Error }