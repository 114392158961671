import { RootState } from "../store"
import { createSelector } from "@reduxjs/toolkit"
import { selectOrganizationById } from './slice'
import { selectAllDevices } from '../device/slice'
import { selectAllUsers } from "../user/slice"

// eslint-disable-next-line
export const selectDevicesByOrganizationId = (organizationId: string) => {
    return createSelector(
        [
            (state: RootState) => selectOrganizationById(state, organizationId),
            (state: RootState) => selectAllDevices(state)
        ],
        (organization, devices) => {
            if (organization === undefined || devices === undefined) {
                return []
            }
            try {
                return Object.keys(devices)
                    .map((d: any) => devices[d])
                    .filter(device => organization.devices.includes(device.id))
            } catch (error) {
                return []
            }
        }
    )
}

// eslint-disable-next-line
export const selectUsersByOrganizationId = (organizationId: string) => {
    return createSelector(
        [
            (state: RootState) => selectOrganizationById(state, organizationId),
            (state: RootState) => selectAllUsers(state)
        ],
        (organization, users) => {
            if (organization === undefined || users === undefined) {
                return []
            }

            try {
                return Object.keys(users)
                    .map((d: any) => users[d])
                    .filter(user => organization.users.includes(user.id))
            } catch (error) {
                return []
            }
        }
    )
}
