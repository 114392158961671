import { createAsyncThunk } from "@reduxjs/toolkit"
import api from "../api"
import { normalize } from "normalizr"
import { User, userSchema } from "../user/types"
import { Device, deviceSchema } from "../device/types"
import { BackendError } from "../errorHandler"
import { SorterResult } from "antd/lib/table/interface"

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'http://localhost:5000/v1'

interface FetchAdminDevicesAttributes {
    token: string
    page: number
    pageSize: number
    sorter?: SorterResult<Device> | SorterResult<Device>[]
    filterText?: string
}

export const fetchAdminDevices = createAsyncThunk<
    any,
    FetchAdminDevicesAttributes,
    {
        rejectValue: BackendError
    }
    >("admin/fetchDevices", async (data, thunkApi) => {
        const { token, page, pageSize, sorter, filterText } = data

    try {
        const request = await api({ token, method: 'post', url: API_ENDPOINT, path: `/admin/devices?page=${page}&per_page=${pageSize}&filtertext=${filterText}`, data: sorter })
        return [request.data['results'], request.data.count]
    } catch (err) {
        return thunkApi.rejectWithValue((await err.response.data) as BackendError)
    }
})

interface FetchAdminUsersAttributes {
    token: string
    page: number
    pageSize: number
}

export const fetchAdminUsers = createAsyncThunk<
    any,
    FetchAdminUsersAttributes,
    {
        rejectValue: BackendError
    }
>("users/fetchAdminUsers", async (data, thunkApi) => {
    const { token, page, pageSize } = data

    try {
        const request = await api({ token, method: 'get', url: API_ENDPOINT, path: `/admin/users?page=${page}&per_page=${pageSize}` })
        const normalized = normalize<
            any,
            {
                users: { [key: string]: User }
            }
        >(request.data['results'], [userSchema])
        return [normalized.entities, request.data.count]
    } catch (err) {
        return thunkApi.rejectWithValue((await err.response.data) as BackendError)
    }
})
