import { ReactElement, useEffect } from "react"
import { useAuth0 } from '@auth0/auth0-react'
import { useAppDispatch } from "../api/store"
import { getSettings } from "../api/settings/thunk"

const SettingsHandler = (): ReactElement => {
    const dispatch = useAppDispatch()
    const { getAccessTokenSilently } = useAuth0()
  
    useEffect(() => {
      getAccessTokenSilently().then(token => dispatch(getSettings(token)))
    }, [])

    return (
        <>
        </>
    )
  
}

export { SettingsHandler }
