import { ReactElement } from "react"
import { NotFound as NotFoundSVG } from "../svg/NotFound"

const NotFound = (): ReactElement => (
    <div style={{ textAlign: "center", paddingTop: "150px" }}>
        <p>We are sorry but the page you are looking for does not exist</p>
        <br/>
        <NotFoundSVG/>
    </div>
)

export { NotFound }
