import { createAsyncThunk } from "@reduxjs/toolkit"
import api from "../api"
import { BackendError } from "../errorHandler"
import { Settings } from "./types"

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'http://localhost:5000/v1'

export const getSettings = createAsyncThunk<
    Settings,
    string,
    {
        rejectValue: BackendError
    }
>(
    "settings/getSettings",
    async (token: string) => {
        const request = await api({ token, method: 'get', url: API_ENDPOINT, path: '/users/me' })
        return request.data
    }
)