import React, { Suspense } from 'react'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { Navigation } from './components/Navigation'
import { Error } from "./components/Error"
import { SplashScreen } from './views/SplashScreen'
import { AbilityContext } from './components/Can'
import { buildAbilityFor } from './config/ability'
import { i18n } from './i18n'
import './fontawesome'
import { I18nextProvider } from "react-i18next"
import { Button, Result } from 'antd'

export const history = createBrowserHistory()

function App(): React.ReactElement {
  const {
    isLoading,
    error,
    user
  } = useAuth0()

  if (isLoading) {
    return (
      <SplashScreen />
    )
  }

  function reloadPage() {
    window.location.href = window.location.origin
  }

  if (error) {
    return <Result
      status="warning"
      title={error.message}
      extra={
        <Button onClick={reloadPage} type="primary">
          Try again!
        </Button>
      }
    />
  }

  return (
    <Router history={history}>
      {error && <Error message={error} />}
      <AbilityContext.Provider value={buildAbilityFor(user)}>
        <Suspense fallback={<div>Loading...</div>}>
          <I18nextProvider i18n={i18n}>
            <Navigation />
          </I18nextProvider>
        </Suspense>
      </AbilityContext.Provider>
    </Router>
  )
}

export { App }