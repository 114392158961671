import { schema } from "normalizr"
import { User, userSchema } from "../user/types"
import { Device, deviceSchema } from "../device/types"

export interface Organization {
    users: [User]
    devices: [Device]
}

export const organizationSchema = new schema.Entity('organizations', {
        users: [userSchema],
        devices: [deviceSchema]
    }
)
