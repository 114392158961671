import { ReactElement, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Layout } from '../../components/layout/Layout'
import moment from "moment"
import { Table, TableProps } from "antd"
import { Spinner } from "../../components/Spinner"
import { RootState, useAppDispatch } from "../../api/store"
import { selectAllOrganizations } from "../../api/organization/slice"
import { fetchOrganizations } from "../../api/organization/thunk"
import { useTranslation } from "react-i18next"
import { useAuth0 } from "@auth0/auth0-react"
import styled from "styled-components"

const StyledTable: any = styled(Table)`
  .ant-table-row:hover {
    cursor: pointer;
  }
`

const Organizations = (): ReactElement => {
    const dispatch = useAppDispatch()
    const organizations = useSelector(selectAllOrganizations)
    const { getAccessTokenSilently } = useAuth0()
    const [t, i18n] = useTranslation('admin')
    const isLoading = useSelector(
        (state: RootState) => state.organizations.isLoading
    )

    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(15)
    const count = useSelector(
        (state: RootState) => state.organizations.count
    )

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (a: any, b: any) => a.id - b.id
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Amount of Users',
            dataIndex: 'users_amount',
            key: 'users_amount',
        },
        {
            title: 'Amount of Devices',
            dataIndex: 'devices_amount',
            key: 'devices_amount',
        },
        {
            title: 'Registration Date',
            dataIndex: 'registration_date',
            key: 'registration_date',
            render: (row: any) => (
                <>
                    {moment(row * 1000).fromNow()}
                </>
            ),
        }
    ]

    const onChange = (page: number, pageSize: number | undefined) => {
        setPage(page)
    }

    const onShowSizeChange = (current: number, pageSize: number) => {
        setPage(current)
        setPageSize(pageSize)
    }

    useEffect(() => {
        getAccessTokenSilently().then(token => dispatch(fetchOrganizations({ token, page, pageSize })))
    }, [])

    return (
        <>
            {isLoading
                ? <Spinner />
                : <Layout
                    headerName={t('organizations')}
                >
                    <StyledTable
                        rowKey="id"
                        dataSource={organizations}
                        columns={columns}
                        scroll={{ x: 500 }}
                        pagination={{
                            pageSize: pageSize,
                            current: page,
                            total: count,
                            showSizeChanger: true,
                            pageSizeOptions: ['5', '10', '15', '20'],
                            onShowSizeChange,
                            onChange
                        }}
                    />
                </Layout>
            }
        </>
    )
}

export { Organizations }
