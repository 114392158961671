import InputMask from "react-input-mask"
import { Breadcrumb, Button, Input, message, Switch, Tooltip } from "antd"
import React, { FC, useEffect, useState } from "react"
import { Formik, useField } from "formik"
import * as Yup from "yup"
import { useHistory } from "react-router"
import { useAppDispatch, RootState } from "../../api/store"
import { claimDevice } from "../../api/device/thunk"
import { Layout } from "../../components/layout/Layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import { InputProps } from "antd/lib/input"
import { ButtonProps } from "antd/lib/button"
import { NavLink } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useAuth0 } from "@auth0/auth0-react"

const StyledMacInput: any = styled(Input)`
  margin-top: 10px;
  margin-bottom: 10px;
  text-transform: uppercase;
`

const StyledPasswordInput: any = styled(Input)`
  margin-top: 10px;
  margin-bottom: 10px;
`

const StyledLabel = styled.div`
  font-weight: bold;
  font-size: 16px;
`

const StyledFeedback = styled.div`
  color: #FF3232;
`

const StyledBreadcrumb = styled(Breadcrumb)`
  margin-bottom: 10px;
`

const StyledButton: any = styled(Button)`
  margin-top: 20px;
`

type FormValues = {
    mac: string
    password: string
    support: boolean
}

interface InputProperties {
    name: string
}


const MacInput: FC<InputProperties> = (props => {
    const [field, { error, touched }] = useField<string>({ name: props.name })
    const [t, i18n] = useTranslation('devices')

    return (
        <>
            <StyledLabel>{t('mac_address')}</StyledLabel>
            <InputMask mask="**-**-**-**-**-**" value={field.value} onChange={field.onChange} onBlur={field.onBlur}>
                {
                    () => (
                        <StyledMacInput
                            type="text"
                            name="mac"
                            value={field.value}
                            size="large">
                        </StyledMacInput>
                    )
                }
            </InputMask>
            {error && touched && <StyledFeedback>{error}</StyledFeedback>}
        </>
    )
})

const PasswordInput: FC<InputProperties> = (props => {
    const [field, { error, touched }] = useField<string>({ name: props.name })
    const [t, i18n] = useTranslation('devices')

    return (
        <>
            <StyledLabel>{t('password')}</StyledLabel>
            <StyledPasswordInput
                type="password"
                name="password"
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value}
                size="large"
            />
            {error && touched && <StyledFeedback>{error}</StyledFeedback>}
        </>
    )
})

const ServiceInput: FC<InputProperties> = (props => {
    const [field, meta, helpers] = useField<boolean>({ name: props.name })
    const [t, i18n] = useTranslation('devices')

    const { value } = meta
    const { setValue } = helpers

    return (
        <>
            <Tooltip placement="topLeft" title={t('service_content')}>
                <StyledLabel>{t('service')} <FontAwesomeIcon icon="info-circle" /></StyledLabel>
            </Tooltip>
            <Switch
                checked={value}
                onChange={(checked => {
                    setValue(checked)
                })}
            />
        </>
    )
})

const Add: React.FunctionComponent = () => {

    const history = useHistory()
    const dispatch = useAppDispatch()
    const [t, i18n] = useTranslation('devices')
    const settings = useSelector((state: RootState) => state.settings.data)
    const [token, setToken] = useState('')
    const { getAccessTokenSilently } = useAuth0()

    useEffect(() => {
        getAccessTokenSilently().then(response => setToken(response))
    }, [])

    return (
        <Formik<FormValues>
            initialValues={{ mac: '', password: '', support: true }}
            onSubmit={
                async (values) => {
                    const request = await dispatch(claimDevice({ token: token, userId: settings.id, mac: values.mac, password: values.password, support: values.support }))
                    if (claimDevice.fulfilled.match(request)) {
                        message.info(request.payload.message)
                        history.push(`/devices/${request.payload.id}`)
                    } else if (claimDevice.rejected.match(request)) {
                        if (request.payload) {
                            message.error(request.payload.message)
                        }
                    }
                }
            }
            validationSchema={
                Yup.object().shape({
                    mac: Yup.string()
                        .required("Mac Address is required")
                        .matches(/^[0-9a-fA-F]{1,2}([.:-])(?:[0-9a-fA-F]{1,2}\1){4}[0-9a-fA-F]{1,2}$/, "Mac Address is not formatted correctly"),
                    password: Yup.string()
                        .min(8, "Password must be at least 8 characters")
                        .length(8, "Password must be exactly 8 characters")
                        .required("Password is required")
                })}
        >
            {(props) => (
                <Layout>
                    <StyledBreadcrumb>
                        <Breadcrumb.Item>
                            <NavLink to="/devices">{t('devices')}</NavLink>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {t('add')}
                        </Breadcrumb.Item>
                    </StyledBreadcrumb>
                    <form onSubmit={props.handleSubmit}>
                        <MacInput name={'mac'} />
                        <PasswordInput name={'password'} />
                        <ServiceInput name={'support'} />
                        <br />
                        <StyledButton
                            htmlType="submit"
                            size="large"
                            type="primary"
                        >
                            {t('add')}
                        </StyledButton>
                    </form>
                </Layout>
            )
            }
        </Formik>
    )
}

export { Add }