import { ReactElement, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Layout } from '../../components/layout/Layout'
import { fetchDevices } from "../../api/device/thunk"
import { RootState, useAppDispatch } from "../../api/store"
import { selectDevicesByUserId } from "../../api/device/selector"
import { DeviceTable } from "../../components/DeviceTable"
import { Badge, Button, Input, Space, TablePaginationConfig } from "antd"
import { useTranslation } from "react-i18next"
import { useAuth0 } from '@auth0/auth0-react'
import { Spinner } from 'src/components/Spinner'
import { ColumnType, FilterValue, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface"
import { Device } from "../../api/device/types"
import { SearchOutlined } from '@ant-design/icons'

const Devices = (): ReactElement => {
    const settings = useSelector((state: RootState) => state.settings.data)
    const devices = useSelector(selectDevicesByUserId(settings.id))
    const dispatch = useAppDispatch()
    const { getAccessTokenSilently } = useAuth0()
    const [t, i18n] = useTranslation('devices')
    const isLoading = useSelector(
        (state: RootState) => state.devices.isLoading
    )

    const [sortObj, setSorter] = useState<SorterResult<Device>>({})
    const [searchText, setSearchText] = useState<string>('')

    const onSearchClick = () => {
        getAccessTokenSilently()
            .then(token => dispatch(fetchDevices({ token, id: settings.id, sorter: sortObj, filterText: searchText })))
    } 

    function mapStatus(status: string) {
        if (status === "active") {
            return <Badge status="success" />
        } return <Badge status="error" />
    }



    const columns = [
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            visible: true,
            render: (text: any, row: any) => (
                <>
                    {mapStatus(row.tunnel_status.status)}
                    {text}
                </>
            ),
            sorter: true,
            sortOrder: sortObj.columnKey === 'type' ? sortObj.order : null,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            visible: true
        },
        {
            title: 'IP Address',
            dataIndex: 'ip',
            key: 'ip',
            visible: true
        },
        {
            title: 'Mac',
            dataIndex: 'mac',
            key: 'mac',
            visible: true,
            sorter: true,
            sortOrder: sortObj.columnKey === 'mac' ? sortObj.order : null,
            filterDropdown: () => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        placeholder={`Mac-address`}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={onSearchClick}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}>
                            Search
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
            ),
        },
        {
            title: 'Registration date',
            dataIndex: 'registration_date',
            key: 'registration_date',
            visible: true,
            render: (row: any) => (
                <>
                    {moment(row * 1000).fromNow()}
                </>
            ),
        }
    ]

    const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<Device> | SorterResult<Device>[], extra: TableCurrentDataSource<Device>) => {
        setSorter(sorter as SorterResult<Device>)
    }

    useEffect(() => {
        if (settings.id === "") {
            return
        }
        getAccessTokenSilently().then(token => dispatch(fetchDevices({ token, id: settings.id, sorter: sortObj, filterText: searchText })))
    }, [settings, sortObj])


    return (
        <>
            {isLoading
                ? <Spinner />
                : <Layout
                    headerName={t('devices')}
                    createParameter="/devices/add"
                >
                    <DeviceTable
                        columns={columns}
                        devices={devices}
                        adminRoute={false}
                        pagination={false}
                        onChange={handleTableChange}
                        />
                </Layout>
            }
        </>
    )
}

export { Devices }
