import { library } from '@fortawesome/fontawesome-svg-core'
import { faDevice } from './svg/device'
import { faHome } from './svg/home'
import {
    faArrowRight,
    faCheck,
    faCreditCard,
    faCubes,
    faFilter,
    faHeadset,
    faIdCard,
    faPlus,
    faQrcode,
    faUnlock,
    faUser,
    faUserFriends,
    faUsers,
    faLanguage,
    faBell,
    faSignOutAlt,
    faCog,
    faPhone,
    faEye,
    faBars,
    faInfoCircle,
    faCopy,
    faBuilding,
    faTrash,
    faCogs,
    faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons'

library.add(
    faCubes,
    faHeadset,
    faUsers,
    faUser,
    faCreditCard,
    faUserFriends,
    faIdCard,
    faArrowRight,
    faFilter,
    faPlus,
    faQrcode,
    faUnlock,
    faCheck,
    faDevice,
    faHome,
    faLanguage,
    faBell,
    faSignOutAlt,
    faCog,
    faPhone,
    faEye,
    faBars,
    faInfoCircle,
    faCopy,
    faBuilding,
    faTrash,
    faCogs,
    faExclamationTriangle
)
