import React, { ReactElement } from "react"
import { Layout } from "../../components/layout/Layout"
import { Descriptions, Table, TableProps, Typography } from "antd"
import styled from "styled-components"

const { Paragraph } = Typography

const StyledParagraph = styled(Paragraph)`
    margin-bottom: 0 !important;
`

const StyledTable: any = styled(Table)`
  .ant-table-row:hover {
    cursor: pointer;
  }
`

const dataSource = [
    {
        key: '1',
        name: 'BusinessCom',
        id: 'businesscom#1772',
    },
    {
        key: '2',
        name: 'Heras',
        id: 'heras#0817',
    },
]

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Communication ID',
        dataIndex: 'id',
        key: 'id',
    }
]

const About = (): ReactElement => {
    return (
        <>
            <Layout
                headerName={"About"}
            >
                <Descriptions bordered>
                    <Descriptions.Item label="Company Name">Heras</Descriptions.Item>
                    <Descriptions.Item label="License">PREMIUM</Descriptions.Item>
                    <Descriptions.Item label="Automatic Renewal">YES</Descriptions.Item>
                    <Descriptions.Item label="Communication ID"><StyledParagraph copyable>heras#018281</StyledParagraph></Descriptions.Item>
                    <Descriptions.Item label="Last User Login" span={2}>
                        2019-04-24 18:00:00
                    </Descriptions.Item>
                </Descriptions>
            </Layout>
            <br/>
            <Layout
                headerName={"Resellers"}
                createParameter={"/org/reseller/add"}
            >
                <StyledTable
                    dataSource={dataSource}
                    columns={columns}
                    bordered
                >

                </StyledTable>
            </Layout>
        </>
    )
}

export { About }