import axios, { AxiosResponse, Method, ResponseType } from "axios"

const instance = axios.create()

interface Arguments {
    token: string
    method: Method 
    url: string 
    path: string
    data?: any
    responseType?: ResponseType
}

const api = (args: Arguments): Promise<AxiosResponse<any>> => {
    return instance.request({
        method: args.method,
        url: `${args.url}${args.path}`,
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${args.token}`
        },
        data: args.data,
        responseType: args.responseType
    })
}

export default api