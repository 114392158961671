// @ts-nocheck

import { NavLink, Redirect, Route, Switch } from "react-router-dom"
import { useContext, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown, Menu } from "antd"
import { AbilityContext } from "./Can"
import { useTranslation } from "react-i18next"
import styled from 'styled-components'
import { useAuth0 } from '@auth0/auth0-react'

import { Devices } from "../views/customer/Devices"
import { Devices as OrgDevices } from "../views/organization/Devices"
import { Support } from "../views/customer/Support"
import { Users as OrgUsers } from "../views/organization/Users"
import { Subscriptions } from "../views/organization/Subscriptions"
import { Profile } from "../views/admin/Profile"
import { Users as AdminUsers } from "../views/admin/Users"
import { Devices as AdminDevices } from "../views/admin/Devices"
import { Organizations as AdminOrganizations } from "../views/admin/Organizations"
import { Details } from "../views/device/Details"
import { Add } from "../views/device/Add"
import { Add as UserAdd } from "../views/user/Add"
import { NotFound } from "../components/NotFound"
import ProtectedRoute from '../components/ProtectedRoute'

import { About } from "../views/organization/About"
import { Provision } from "../views/organization/Provision"
import { Add as ResellerAdd } from "../views/reseller/Add"
import { Welcome } from "../views/Welcome"
import { CheckEmail } from "../views/CheckEmail"
import { SettingsHandler } from "../components/SettingsHandler"

const StyledContent = styled.div`
  position: relative;
  display: block;
  z-index: auto;
  padding-right: 15px;
  width: 100%;
`

const StyledRouterOutlet = styled.div`
  margin-left: 230px;
  margin-right: 10px;
  padding-top: 65px;
  
  @media (max-width: 48rem) {
    margin-left: 10px;
    margin-right: 10px;
  }
`

const activeClassName = 'isActive'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  button {
    font-size: medium;
    width: 200px;
    background-color: transparent;
    color: #434968;
    border: none;
    height: 40px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    p {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 0.6em;
      align-content: center;
    }
  }
  
  button:hover {
    cursor: pointer;
  }
  
  &.${activeClassName} {
    button {
      background-color: #434968;
      color: white;
    }
  }
`

interface NavigationProps {
  open: boolean
}

export const StyledSidebar = styled.div<NavigationProps>`
  display: flex;
  position: fixed;
  flex-direction: column;
  height: 100vh;
  width: 220px;
  padding-top: 48px;
  z-index: 1;
  
  button {
    margin-left: 10px;
  }
  
  .menuHeader {
    margin-left: 10px;
  }
  
  @media (max-width: 48rem) {
    display: ${({ open }) => open ? 'block' : 'none'};
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
    transition: transform 0.3s ease-in-out;
    background-color: #f2f2f2;
    z-index: 1;
  }
`

export const StyledBurgerButton = styled.button<NavigationProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.4rem;
  height: 1.4rem;
  margin-top: 12px;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  background-color: inherit;
  &:focus {
    outline: none;
  }
  
  @media (min-width: 48.1rem) {
    display: none;
  }

  div {
    width: 1.5rem;
    height: 0.20rem;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    background: #5e6375;

    :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`

const StyledUserMenu = styled.div`
  padding-top: 10px;
  margin-bottom: 20px;
`

const StyledOrgMenu = styled.div`
  margin-bottom: 20px;
`

const StyledMenuLogo = styled(Menu.Item)`
  color: #434968 !important;
  font-weight: 700;
  font-size: 16px;
  cursor: unset;
`

const StyledTopNavigation = styled(Menu)`
  background-color: #f2f2f2;
  width: 100%;
  overflow: hidden;
  position: fixed;
  display: flex;
  justify-content: space-between;
  z-index: 10;
  
  .logo {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    width: 100%;
  }
`

function Navigation(): React.ReactElement {
  const ability = useContext(AbilityContext)
  const [isOpen, setOpen] = useState(false)
  const [t, i18n] = useTranslation('navigation')
  const { logout, isAuthenticated } = useAuth0()

  function logoutAndRedirect() {
    logout({ returnTo: window.location.origin })
  }

  function AccountMenu() {
    return (
      <Menu>
        {ability.can('manage', 'Organization') && (
          <Menu.Item>
            <NavLink to="/org/reseller">
              <FontAwesomeIcon fixedWidth icon="building" /> My Organization
            </NavLink>
          </Menu.Item>)}
        <Menu.Item onClick={logoutAndRedirect}>
          <FontAwesomeIcon fixedWidth icon="sign-out-alt" /> Logout
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <>
    {isAuthenticated && 
      <>
      <SettingsHandler></SettingsHandler>
      <StyledContent>
        <StyledTopNavigation mode="horizontal">
          <Menu.Item key="hamburger" className="hamburgerMenu" onClick={() => setOpen(!isOpen)}>
            <StyledBurgerButton open={isOpen}>
              <div />
              <div />
              <div />
            </StyledBurgerButton>
          </Menu.Item>
          <StyledMenuLogo className="logo" key="logo" disabled>
            PortaDial Connect
          </StyledMenuLogo>
          <Menu.Item
            key="account"
            className="menuItem"
          >
            <Dropdown overlay={AccountMenu} placement="bottomCenter">
              <a onClick={e => e.preventDefault()}>
                <FontAwesomeIcon fixedWidth icon="user" />
              </a>
            </Dropdown>
          </Menu.Item>
        </StyledTopNavigation>
        <StyledSidebar open={isOpen}>
          <StyledUserMenu>
            <StyledNavLink to={"/devices"} activeClassName="isActive">
              <button onClick={() => setOpen(false)}><FontAwesomeIcon fixedWidth icon="device" />
                <p>{t('devices')}</p>
              </button>
            </StyledNavLink>
            <StyledNavLink to="/support" activeClassName="isActive">
              <button onClick={() => setOpen(false)}><FontAwesomeIcon fixedWidth icon="headset" />
                <p>{t('support')}</p></button>
            </StyledNavLink>
          </StyledUserMenu>
          {ability.can('manage', 'Organization') && (
            <StyledOrgMenu>
              <p className="menuHeader">{t('organization')}</p>
              <StyledNavLink to="/org/users" activeClassName="isActive">
                <button onClick={() => setOpen(false)}><FontAwesomeIcon fixedWidth icon="users" />
                  <p>{t('users')}</p></button>
              </StyledNavLink>
              <StyledNavLink to="/org/devices" activeClassName="isActive">
                <button onClick={() => setOpen(false)}><FontAwesomeIcon fixedWidth icon="device" />
                  <p>{t('devices')}</p></button>
              </StyledNavLink>
              {/* <StyledNavLink to="/org/subscriptions" activeClassName="isActive">
                <button onClick={() => setOpen(false)}><FontAwesomeIcon fixedWidth icon="credit-card" />
                  <p>{t('subscriptions')}</p></button>
              </StyledNavLink> */}
            </StyledOrgMenu>
          )}
          {ability.can('manage', 'all') && (
            <>
              <p className="menuHeader">{t('admin')}</p>
              <StyledNavLink to="/admin/users" activeClassName="isActive">
                <button onClick={() => setOpen(false)}><FontAwesomeIcon fixedWidth icon="users" />
                  <p>{t('users')}</p></button>
              </StyledNavLink>
              <StyledNavLink to="/admin/devices" activeClassName="isActive">
                <button onClick={() => setOpen(false)}><FontAwesomeIcon fixedWidth icon="device" />
                  <p>{t('devices')}</p></button>
              </StyledNavLink>
              <StyledNavLink to="/admin/organizations" activeClassName="isActive">
                <button onClick={() => setOpen(false)}><FontAwesomeIcon fixedWidth icon="user-friends" />
                  <p>{t('organizations')}</p></button>
              </StyledNavLink>
            </>
          )}
        </StyledSidebar>
      </StyledContent>
      </>
}

      <StyledRouterOutlet>
        <Switch>
        <ProtectedRoute exact path='/devices' component={Devices} />
          <ProtectedRoute exact path='/devices/add' component={Add} />
          <ProtectedRoute path='/devices/:id' component={Details} />
          <ProtectedRoute exact path='/support' component={Support} />
          <ProtectedRoute exact path='/org/devices' component={OrgDevices} />
          <ProtectedRoute exact path='/org/users' component={OrgUsers} />
          <ProtectedRoute exact path='/org/users/add' component={UserAdd} />
          <ProtectedRoute exact path='/org/subscriptions' component={Subscriptions} />
          <ProtectedRoute exact path='/org/reseller' component={About} />
          <ProtectedRoute exact path='/org/reseller/add' component={ResellerAdd} />
          <ProtectedRoute exact path='/admin/profile' component={Profile} />
          <ProtectedRoute exact path='/admin/users' component={AdminUsers} />
          <ProtectedRoute exact path='/admin/devices' component={AdminDevices} />
          <ProtectedRoute exact path='/admin/organizations' component={AdminOrganizations} />
          <ProtectedRoute exact path='/provision/' component={Provision} />
          <Route exact path='/welcome' component={Welcome} />
          <Route exact path='/check-email' component={CheckEmail} />
          <Redirect exact from='/' to='devices' />
          <Route path='*' component={NotFound} />
        </Switch>
      </StyledRouterOutlet>
    </>
  )
}

export { Navigation }
