import { ReactElement, useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import { adminRemoveDevice } from "../api/device/thunk"
import { useAppDispatch } from "../api/store"
import { Button, message } from "antd"


interface Props {
    id: string
    mac: string
}

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    float: right;
`

const ManageDevice = (props: Props): ReactElement => {
    const dispatch = useAppDispatch()
    const { mac } = props

    function redirectToDevice(mac: string) {
        if (mac === undefined) {
            message.error('Device is not available!')
        } else {
            window.open(`https://${mac.split('-').join("")}.tunnel.portadial.nl`, "_blank")
        }
    }

    return (
        <Button
            onClick={() => redirectToDevice(mac)}
        >
            <StyledFontAwesomeIcon icon="cogs" />
        </Button>
    )
}

export { ManageDevice }