import i18n from "i18next"
import Backend from "i18next-http-backend"
import { initReactI18next } from "react-i18next"

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: 'en',
        interpolation: {
            escapeValue: false
        },
        react: {
            useSuspense: true
        },
        detection: {
            order: ['queryString', 'cookie'],
            cache: ['cookie']
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json'
        }
    })

export { i18n }