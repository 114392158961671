import { schema } from "normalizr"
import { deviceSchema } from "../device/types"

export interface User {
    devices: []
    id: any
    // devices_claimed: number
    // first_name: string
    // last_name: string
    // username: string
    // uuid: string,
}

export const userSchema = new schema.Entity('users', {
    devices: [deviceSchema]
})
