import { createAsyncThunk } from "@reduxjs/toolkit"
import api from "../api"
import { normalize } from "normalizr"
import { Organization, organizationSchema } from "./types"
import { Device } from "../device/types"
import { User } from "../user/types"
import { BackendError } from "../errorHandler"

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'http://localhost:5000/v1'

interface FetchOrganizationAttributes {
    token: string
    id: string
}

export const fetchOrganization = createAsyncThunk<
    any,
    FetchOrganizationAttributes,
    {
        rejectValue: BackendError
    }
>("organizations/fetchOrganization", async (data, thunkApi) => {
    try {
        const { token, id } = data
        const request = await api({ token, method: 'get', url: API_ENDPOINT, path: `/organizations/${id}` })
        const normalized = normalize(request.data, [organizationSchema])
        return normalized.entities
    } catch (err) {
        return thunkApi.rejectWithValue((await err.response.data) as BackendError)
    }
})

interface FetchOrganizationsAttributes {
    token: string
    page: number
    pageSize: number
}

export const fetchOrganizations = createAsyncThunk<
    any,
    FetchOrganizationsAttributes,
    {
        rejectValue: BackendError
    }
>("organizations/fetchOrganizations", async (data, thunkApi) => {
    const { token, page, pageSize } = data
    
    try {
        const request = await api({ token, method: 'get', url: API_ENDPOINT, path: `/admin/organizations?page=${page}&per_page=${pageSize}` })
        const normalized = normalize<any,
            {
                organizations: { [key: string]: Organization }
            }>(request.data['results'], [organizationSchema])
        return [ normalized.entities, request.data.count ]
    } catch (err) {
        return thunkApi.rejectWithValue((await err.response.data) as BackendError)
    }
})

interface FetchOrganizationDevicesAttributes {
    token: string
    id: string
}

export const fetchOrganizationDevices = createAsyncThunk<
    any,
    FetchOrganizationDevicesAttributes,
    {
        rejectValue: BackendError
    }
>("organizations/fetchOrganizationDevices", async (data, thunkApi) => {
    const { token, id } = data

    if (id === "") {
        return thunkApi.rejectWithValue({ message: 'No settings provided!' })
    }

    try {
        const request = await api({ token, method: 'get', url: API_ENDPOINT, path: `/organizations/${id}/devices` })
        const normalized = normalize<any,
            {
                organizations: { [key: string]: Organization }
                users: { [key: string]: User }
                devices: { [key: string]: Device }
            }>(request.data, organizationSchema)
        return normalized.entities
    } catch (err) {
        return thunkApi.rejectWithValue((await err.response.data) as BackendError)
    }
})

interface FetchOrganizationUsersAttributes {
    token: string
    id: string
}

export const fetchOrganizationUsers = createAsyncThunk<
    any,
    FetchOrganizationUsersAttributes,
    {
        rejectValue: BackendError
    }
>("users/fetchOrganizationUsers", async (data, thunkApi) => {
    const { token, id } = data

    if (id === "") {
        return thunkApi.rejectWithValue({ message: 'No settings provided!' })
    }

    try {
        const request = await api({ token, method: 'get', url: API_ENDPOINT, path: `/organizations/${id}/users` })
        const normalized = normalize<
            any,
            {
                organizations: { [key: string]: Organization }
                users: { [key: string]: User }
                devices: { [key: string]: Device }
            }
        >(request.data, organizationSchema)
        return normalized.entities
    } catch (err) {
        return thunkApi.rejectWithValue((await err.response.data) as BackendError)
    }
})

interface ProvisionAttributes {
    token: string
    organizationId: string
    devices: string[]
}

export const provisionDevices = createAsyncThunk<
    any,
    ProvisionAttributes,
    {
        rejectValue: BackendError
    }
>("devices/provision", async (data, thunkApi) => {
    const { token, devices, organizationId } = data

    try {
        const request = await api({ token, method: 'post', url: API_ENDPOINT, path: `/organizations/${organizationId}/provision`, data: { "devices": devices }, responseType: "arraybuffer" })
        return (await request.data)
    } catch (err) {
        return thunkApi.rejectWithValue((await err.response.data) as BackendError)
    }
})