import React, { FC, useEffect, useState } from "react"
import { Formik, useField } from "formik"
import { AutoComplete, Breadcrumb, Button, message } from "antd"
import { useHistory } from "react-router"
import { useAppDispatch } from "../../api/store"
import { claimDevice, fetchDevice } from "../../api/device/thunk"
import * as Yup from "yup"
import { Layout } from "../../components/layout/Layout"
import styled from "styled-components"
import { NavLink } from "react-router-dom"
import { AutoCompleteProps } from "antd/lib/auto-complete"
import { useTranslation } from "react-i18next"
import { useAuth0 } from "@auth0/auth0-react"

const StyledCommunicationIDInput: any = styled(AutoComplete)`
  margin-top: 10px;
  margin-bottom: 15px;
  width: 100%;
`

const StyledLabel = styled.div`
  font-weight: bold;
  font-size: 16px;
`

const StyledFeedback = styled.div`
  color: #FF3232;
`

const StyledBreadcrumb = styled(Breadcrumb)`
    margin-bottom: 10px;
`

type FormValues = {
    email: string
}

interface InputProperties {
    name: string
}

const { Option } = AutoComplete

const EmailInput: FC<InputProperties> = (props => {
    const [field, { error, touched }, { setValue }] = useField<string>({ name: props.name })
    const [t, i18n] = useTranslation('reseller')
    // const [results, setResult] = useState<string[]>([])

    return (
        <>
            <StyledLabel>{t('communication_id')}</StyledLabel>
            <StyledCommunicationIDInput size="large">
                {/*    {results.map((email: string) => (*/}
                {/*        <Option*/}
                {/*            key={email} value={email}*/}
                {/*        >*/}
                {/*            {email}*/}
                {/*        </Option>*/}
                {/*    ))}*/}
            </StyledCommunicationIDInput>
            {error && touched && <StyledFeedback>{error}</StyledFeedback>}
        </>
    )
})

const Add: React.FunctionComponent = () => {

    const history = useHistory()
    const dispatch = useAppDispatch()
    const [t, i18n] = useTranslation('reseller')
    const [token, setToken] = useState('')
    const { getAccessTokenSilently } = useAuth0()

    useEffect(() => {
        getAccessTokenSilently().then(response => setToken(response))
    }, [])

    return (
        <Formik<FormValues>
            initialValues={{ email: '' }}
            onSubmit={
                async (values) => {
                    const request = await dispatch(fetchDevice({ token, id: '', userId: '' }))
                    if (claimDevice.fulfilled.match(request)) {
                        message.info(request.payload.message)
                        history.push(`/devices/${request.payload.id}`)
                    } else if (claimDevice.rejected.match(request)) {
                        if (request.payload) {
                            message.error(request.payload.message)
                        }
                    }
                }
            }
            validationSchema={
                Yup.object().shape({
                    email: Yup.string().email("You need to fill in your email")
                })}
        >
            {(props) => (
                <Layout>
                    <StyledBreadcrumb>
                        <Breadcrumb.Item>{t('organization')}</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <NavLink to="/org/reseller">{t('reseller')}</NavLink>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {t('add')}
                        </Breadcrumb.Item>
                    </StyledBreadcrumb>
                    <form onSubmit={props.handleSubmit}>
                        <EmailInput name={'email'} />
                        <br />
                        <Button
                            htmlType="submit"
                            size="large"
                            type="primary"
                        >
                            {t('add')}
                        </Button>
                    </form>
                </Layout>
            )
            }
        </Formik>
    )
}

export { Add }