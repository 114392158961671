import { createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"
import { fetchOrganizationDevices, fetchOrganizations, fetchOrganizationUsers } from "./thunk"
import { Organization } from "./types"

const organizationsAdapter = createEntityAdapter<Organization>()

export const slice = createSlice({
    name: 'organizations',
    initialState: {
        entities: {}, 
        ids: [],
        error: '',
        isLoading: true,
        count: 15
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchOrganizationDevices.fulfilled, (state, action) => {
            organizationsAdapter.upsertMany(state, action.payload.organizations)
            state.isLoading = false
        })
        builder.addCase(fetchOrganizationDevices.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchOrganizationUsers.fulfilled, (state, action) => {
            organizationsAdapter.upsertMany(state, action.payload.organizations)
            state.isLoading = false
        })
        builder.addCase(fetchOrganizationUsers.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchOrganizations.fulfilled, (state, action) => {
            organizationsAdapter.upsertMany(state, action.payload[0].organizations)
            state.count = action.payload[1]
            state.isLoading = false
        })
        builder.addCase(fetchOrganizations.pending, (state) => {
            state.isLoading = true
        })
    }
})

const reducer = slice.reducer
export default reducer

export const {
    selectById: selectOrganizationById,
    selectIds: selectOrganizationIds,
    selectEntities: selectOrganizationEntities,
    selectAll: selectAllOrganizations,
    selectTotal: selectTotalOrganizations
} = organizationsAdapter.getSelectors((state: RootState) => state.organizations)
