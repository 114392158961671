import { ReactElement, useEffect, useState } from "react"
import { Layout } from "../../components/layout/Layout"
import moment from "moment"
import { fetchAdminDevices } from "../../api/admin/thunk"
import { RootState, useAppDispatch } from "../../api/store"
import { useSelector } from "react-redux"
import { selectAllDevices } from "../../api/device/slice"
import { DeviceTable } from "../../components/DeviceTable"
import { Spinner } from "../../components/Spinner"
import { PasswordReveal } from "../../components/PasswordReveal"
import { RemoveDevice } from "../../components/RemoveDevice"
import { ManageDevice } from "../../components/ManageDevice"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { useAuth0 } from "@auth0/auth0-react"
import { Badge, Button, Input, Space, TablePaginationConfig } from "antd"
import { ColumnType, FilterValue, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface"
import { Device } from "../../api/device/types"
import { SearchOutlined } from '@ant-design/icons'

const StyledMacField = styled.div`
    white-space: nowrap;
`

const Devices = (): ReactElement => {
    const dispatch = useAppDispatch()
    const devices = useSelector(selectAllDevices)
    const { getAccessTokenSilently } = useAuth0()
    const isLoading = useSelector(
        (state: RootState) => state.devices.isLoading
    )
    const [t, i18n] = useTranslation('admin')

    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(15)
    const count = useSelector(
        (state: RootState) => state.devices.count
    )

    const [sortObj, setSorter] = useState<SorterResult<Device>>({})
    const [searchText, setSearchText] = useState<string>('')

    const onSearchClick = () => {
        getAccessTokenSilently()
            .then(token => dispatch(fetchAdminDevices({ token, page, pageSize, sorter: sortObj, filterText: searchText })))
    } 

    const columns = [
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            visible: true,
            render: (text: any, row: any) => (
                <>
                    {mapStatus(row.tunnel_status.status)}
                    {text}
                </>
            ),
            sorter: true,
            sortOrder: sortObj.columnKey === 'type' ? sortObj.order : null,
        },
        {
            title: 'Mac',
            dataIndex: 'mac',
            key: 'mac',
            visible: true,
            render: (row: any) => (
                <StyledMacField>{row}</StyledMacField>
            ),
            sorter: true,
            sortOrder: sortObj.columnKey === 'mac' ? sortObj.order : null,
            filterDropdown: () => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        placeholder={`Mac-address`}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={onSearchClick}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}>
                            Search
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered: boolean) => (
                <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            visible: true
        },
        {
            title: 'IP Address',
            dataIndex: 'ip',
            key: 'ip',
            visible: true,
        },
        {
            title: 'Registration date',
            dataIndex: 'registration_date',
            key: 'registration_date',
            visible: true,
            render: (row: any) => (
                <>
                    {moment(row * 1000).fromNow()}
                </>
            ),
        },
        {
            title: 'Reveal Password',
            dataIndex: 'password',
            key: 'password',
            visible: true,
            width: 200,
            render: (_: any, record: any) =>
                <PasswordReveal id={record.id} />
        },
        {
            title: 'Manage',
            dataIndex: 'manage_device',
            key: 'manage_device',
            visible: true,
            width: 10,
            render: (_: any, record: any) => {
                if (record.support) {
                    return (<ManageDevice id={record.id} mac={record.mac} />)
                }
                return (<></>)
            }
        },
        {
            title: 'Revoke',
            dataIndex: 'revoke_device',
            key: 'revoke_device',
            visible: true,
            width: 10,
            render: (_: any, record: any) =>
                <RemoveDevice id={record.id} mac={record.mac} />
        }
    ]

    function mapStatus(status: string) {
        if (status === "active") {
            return <Badge status="success" />
        } return <Badge status="error" />
    }

    const onChange = (page: number, pageSize: number | undefined) => {
        setPage(page)
    }

    const onShowSizeChange = (current: number, pageSize: number) => {
        setPage(current)
        setPageSize(pageSize)
    }

    const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<Device> | SorterResult<Device>[], extra: TableCurrentDataSource<Device>) => {
        setSorter(sorter as SorterResult<Device>)
    }

    useEffect(() =>
    {
        getAccessTokenSilently()
            .then(token => dispatch(fetchAdminDevices({ token, page, pageSize, sorter: sortObj, filterText: searchText })))
    }, [page, pageSize, sortObj])

    return (
        <>
            {isLoading
                ? <Spinner /> :
                <Layout headerName={t('devices')}>
                    <DeviceTable
                        devices={devices}
                        columns={columns}
                        adminRoute={true}
                        pagination={{
                            pageSize: pageSize,
                            current: page,
                            total: count,
                            showSizeChanger: true,
                            pageSizeOptions: ['5', '10', '15', '20'],
                            onShowSizeChange,
                            onChange,
                            showLessItems: true,
                        }}
                        onChange={handleTableChange}
                    />
                </Layout>
            }
        </>
    )
}

export { Devices }
