import { Table, TablePaginationConfig, TableProps } from "antd"
import React, { ReactElement } from "react"
import { Device } from "../api/device/types"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { FilterValue, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface"

const StyledTable: any = styled(Table)`
  .ant-table-row:hover {
    cursor: pointer;
  }
`

interface ColumnDefinition {
    title: string
    dataIndex: string
    key: string
    visible: boolean
    sorter?: any
    sortOrder?: any
}

interface Props {
    columns: ColumnDefinition[]
    devices: Device[]
    adminRoute: boolean
    pagination?: false | TablePaginationConfig
    onShowSizeChange?: (pagination: TablePaginationConfig) => void
    onChange?: (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<Device> | SorterResult<Device>[], extra: TableCurrentDataSource<Device>) => void
}

const DeviceTable = (props: Props): ReactElement => {

    const { columns, devices, adminRoute, pagination, onChange } = props

    const history = useHistory()

    function navigateDevice(id: number) {
        history.push(`/devices/${id}`)
    }

    return (
        <>
            <StyledTable
                rowKey="id"
                dataSource={devices}
                columns={columns}
                scroll={{ x: 500 }}
                pagination={pagination}
                onRow={(record: any) => {
                    return {
                        onClick: (_: any) => {
                            if (!adminRoute) {
                                navigateDevice(record.id)
                            }
                        }
                    }
                }}
                onChange={onChange}
            />
        </>
    )
}

export { DeviceTable }