import { IconDefinition,IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core"

export const faDevice: IconDefinition = {
    prefix: 'fas' as IconPrefix,
    iconName: 'device' as IconName,
    icon: [
        279,
        512,
        [],
        '',
        'M237.4,0H41.9C18.7,0,0,21.5,0,48V464c0,26.5,18.7,48,41.9,48h195.4c23.1,0,41.9-21.5,41.9-48V48C279.2,21.5,260.5,0,237.4,0z M140.1,455.4c-21.4,0-38.7-17.3-38.7-38.7s17.3-38.7,38.7-38.7s38.7,17.3,38.7,38.7S161.5,455.4,140.1,455.4z M227,249.5H53.2v-27H227V249.5z M227,179.9H53.2v-27H227V179.9z M227,110.4H53.2v-27H227V110.4z'
    ]
}
