import { ReactElement, useEffect } from "react"
import { Layout } from "../../components/layout/Layout"
import moment from "moment"
import { fetchOrganizationDevices } from "../../api/organization/thunk"
import { useAppDispatch, RootState } from "../../api/store"
import { selectDevicesByOrganizationId } from "../../api/organization/selector"
import { useSelector } from "react-redux"
import { OrganizationDeviceTable } from "../../components/OrganizationDeviceTable"
import { useTranslation } from "react-i18next"
import { useAuth0 } from "@auth0/auth0-react"
import { Spinner } from "src/components/Spinner"
import { Badge } from "antd"

const Devices = (): ReactElement => {
    const dispatch = useAppDispatch()
    const settings = useSelector((state: RootState) => state.settings.data)
    const devices = useSelector(selectDevicesByOrganizationId(settings.organizations[0]))
    const isLoading = useSelector(
        (state: RootState) => state.organizations.isLoading
    )
    const [t, i18n] = useTranslation('organizations')
    const { getAccessTokenSilently } = useAuth0()

    const columns = [
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            visible: true
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            visible: true
        },
        {
            title: 'IP Address',
            dataIndex: 'ip',
            key: 'ip',
            visible: true
        },
        {
            title: 'Mac',
            dataIndex: 'mac',
            key: 'mac',
            visible: true
        },
        {
            title: 'Registration date',
            dataIndex: 'registration_date',
            key: 'registration_date',
            visible: true,
            render: (row: any) => (
                <>
                    {moment(row * 1000).fromNow()}
                </>
            )
        }
    ]

    useEffect(() => {
        if (settings.organizations.length === 0) {
            return
        }
        getAccessTokenSilently().then(token => dispatch(fetchOrganizationDevices({ token, id: settings.organizations[0] })))
    }, [settings])

    return (
        <>
        {isLoading
            ? <Spinner />
            : <Layout 
            headerName={t('devices')}
        >
            <OrganizationDeviceTable devices={devices} columns={columns} />
        </Layout>
        }
        </>
    )
}

export { Devices }
