import { createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"
import { Device } from "./types"
import { fetchOrganizationDevices } from "../organization/thunk"
import { claimDevice, fetchDevice, fetchDevices } from "./thunk"
import { fetchAdminDevices } from "../admin/thunk"

export const devicesAdapter = createEntityAdapter<Device>({ sortComparer: undefined })

export const slice = createSlice({
    name: 'devices',
    initialState: {
        entities: {},
        ids: [],
        error: '',
        isLoading: true,
        count: 15
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchOrganizationDevices.fulfilled, (state, action) => {
            devicesAdapter.upsertMany(state, action.payload.devices)
            state.isLoading = false
        })
        builder.addCase(fetchOrganizationDevices.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchDevices.fulfilled, (state, action) => {
            state.isLoading = false
            if (action.payload.devices === undefined) {
                return
            }
            devicesAdapter.setAll(state, action.payload.devices)
        })
        builder.addCase(fetchDevices.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchDevice.fulfilled, (state, action) => {
            devicesAdapter.upsertMany(state, action.payload.devices)
            state.isLoading = false
        })
        builder.addCase(fetchDevice.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchAdminDevices.fulfilled, (state, action) => {
            devicesAdapter.setAll(state, action.payload[0])
            state.count = action.payload[1]
            state.isLoading = false
        })
        builder.addCase(fetchAdminDevices.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(claimDevice.fulfilled, (state, action) => {
            // devicesAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(claimDevice.rejected, (state, action) => {
            if (action.payload) {
                state.error = action.payload.message
            }
        })
    }
})

const reducer = slice.reducer
export default reducer

export const {
    selectById: selectDeviceById,
    selectIds: selectDeviceIds,
    selectEntities: selectDeviceEntities,
    selectAll: selectAllDevices,
    selectTotal: selectTotalDevices
} = devicesAdapter.getSelectors((state: RootState) => state.devices)
