import React, { ReactElement } from "react"
import styled, { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    background: aliceblue;
  }
`

const LogoContainer = styled.div`
    display: flex;
    justify-content:center; // centers in the flex direction and the default flex-direction is row
    align-items:center; // centers perpendicular to the flex direction
    height: 100vh; // 100% view height
    width: 100vw; // 100% view width
    position: absolute; // so it goes behind the current content
`

const SplashScreen = (): ReactElement => {
    return (
        <>
            <GlobalStyle />
            <LogoContainer>
                <img className="logo" alt="logo" src={process.env.PUBLIC_URL + '/logo_connect.png'} />
            </LogoContainer>
        </>
    )
}

export { SplashScreen }