// @ts-nocheck

import { withAuthenticationRequired } from "@auth0/auth0-react"
import { Route } from "react-router"
import { SplashScreen } from "../views/SplashScreen"

const ProtectedRoute = ({ component, ...args }) => (
    <Route
        component={withAuthenticationRequired(component, {
            onRedirecting: () => <SplashScreen />
        })}
        {...args}
    />
)

export default ProtectedRoute