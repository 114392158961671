import { ReactElement, useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import { adminRemoveDevice } from "../api/device/thunk"
import { useAppDispatch } from "../api/store"
import { Button, message, Modal, Input } from "antd"
import InputMask from "react-input-mask"
import { useHistory } from "react-router"

interface Props {
    id: string
    mac: string
}

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    float: right;
`

const RemoveDevice = (props: Props): ReactElement => {
    const dispatch = useAppDispatch()
    const { id, mac } = props
    const { getAccessTokenSilently } = useAuth0()
    const [visible, setVisible] = useState(false)
    const [macAddressValue, setMacAddressValue] = useState('')
    const history = useHistory()

    function removeDevice() {
        getAccessTokenSilently().then(async token => {
            const request = await dispatch(adminRemoveDevice({ token, deviceId: id, macAddress: macAddressValue }))
            if (adminRemoveDevice.fulfilled.match(request)) {
                message.info(request.payload.message)
                history.push('/admin/devices')
            } else if (adminRemoveDevice.rejected.match(request)) {
                message.error("Invalid mac address provided. Please try again.")
            }
        })
    }

    return (
        <>
            <Modal
                visible={visible}
                title={<><FontAwesomeIcon icon="exclamation-triangle" /> Are you sure to delete this device?</>}
                onOk={removeDevice}
                onCancel={() => setVisible(false)}
            >
                You are about to completely remove this device permanently from the Connect platform. When this device reboots, it will be added again. Users are then able to claim this device.
                <br/>
                <br/>
                <b>For validation purposes, please enter the mac address of the device. </b>
                <InputMask mask="**-**-**-**-**-**" value={macAddressValue} onChange={e => setMacAddressValue(e.target.value)}>
                    {
                        (
                            <Input
                            style={{ marginTop: 10, marginBottom: 10, textTransform: 'uppercase' }}
                                type="text"
                                name="mac"
                                size="large">
                            </Input>
                        )
                    }
                </InputMask>
            </Modal>

            <Button
                onClick={() => setVisible(true)}
            >
                <StyledFontAwesomeIcon icon="trash" />
            </Button>
        </>
    )
}

export { RemoveDevice }