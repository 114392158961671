import { createAsyncThunk } from "@reduxjs/toolkit"
import api from "../api"
import { normalize } from "normalizr"
import { Device, deviceSchema } from "./types"
import { User, userSchema } from "../user/types"
import { BackendError } from "../errorHandler"
import { SorterResult } from "antd/lib/table/interface"

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'http://localhost:5000/v1'

interface FetchDeviceArguments {
    token: string
    userId: string
    id: string
}

export const fetchDevice = createAsyncThunk<
    any,
    FetchDeviceArguments,
    {
        rejectValue: BackendError
    }
>("devices/fetchDevice", async (data, thunk) => {
    const { token, userId, id } = data
    try {
        const request = await api({ token, method: 'get', url: API_ENDPOINT, path: `/users/${userId}/devices/${id}` })
        const normalized = normalize<any,
            {
                devices: { [key: string]: Device }
            }>(request.data, deviceSchema)

        return normalized.entities
    } catch (err) {
        return thunk.rejectWithValue((await err.response.data) as BackendError)
    }
})

interface FetchDevicesArguments {
    token: string
    id: string
    sorter?: SorterResult<Device> | SorterResult<Device>[]
    filterText?: string
}

export const fetchDevices = createAsyncThunk<
    any,
    FetchDevicesArguments,
    {
        rejectValue: BackendError
    }
>("devices/fetchDevices", async (data, thunkApi) => {
    const { token, id, sorter, filterText } = data
    try {
        const request = await api({ token, method: 'post', url: API_ENDPOINT, path: `/users/${id}/devices?filtertext=${filterText}`, data: sorter })
        const normalized = normalize<any,
            {
                users: { [key: string]: User }
                devices: { [key: string]: Device }
            }>(request.data, userSchema)
        return normalized.entities
    } catch (err) {
        return thunkApi.rejectWithValue((await err.response.data) as BackendError)
    }
})

interface ClaimData {
    id: string
    message: string
}

interface ClaimDeviceAttributes {
    token: string
    userId: string
    mac: string
    password: string
    support: boolean
}

export const claimDevice = createAsyncThunk<
    ClaimData,
    ClaimDeviceAttributes,
    {
        rejectValue: BackendError
    }
>("devices/claimDevice", async (data, thunkApi) => {
    const { token, userId, mac, password, support } = data
    try {
        const request = await api({ token, method: 'post', url: API_ENDPOINT, path: `/users/${userId}/devices/claim`, data: { mac, password, support } })
        return (await request.data) as ClaimData
    } catch (err) {
        return thunkApi.rejectWithValue((await err.response.data) as BackendError)
    }
})

interface PasswordData {
    message: string
}

interface RevealPasswordAttributes {
    token: string
    id: string
}

export const revealPassword = createAsyncThunk<
    PasswordData,
    RevealPasswordAttributes,
    {
        rejectValue: BackendError
    }
>("devices/revealPassword", async (data, thunkApi) => {
    try {
        const { token, id } = data
        const request = await api({ token, method: 'get', url: API_ENDPOINT, path: `/admin/devices/${id}/password` })
        return (await request.data) as PasswordData
    } catch (err) {
        return thunkApi.rejectWithValue((await err.response.data) as BackendError)
    }
})

interface RemoveDeviceAttributes {
    token: string
    userId: string
    deviceId: string
    mac: string
}

export const removeDevice = createAsyncThunk<
    string,
    RemoveDeviceAttributes,
    {
        rejectValue: BackendError
    }
>("devices/removeDevice", async (data, thunkApi) => {
    try {
        const { token, userId, deviceId, mac } = data
        const request = await api({ token, method: 'delete', url: API_ENDPOINT, path: `/users/${userId}/devices/${deviceId}`, data: { mac: mac } })
        return (await request.data) as string
    }
    catch (err) {
        return thunkApi.rejectWithValue((await err.response.data) as BackendError)
    }
})

interface AdminRemoveDeviceAttributes {
    token: string
    deviceId: string
    macAddress: string
}

interface AdminRemoveDeviceData {
    message: string
}

export const adminRemoveDevice = createAsyncThunk<
    AdminRemoveDeviceData,
    AdminRemoveDeviceAttributes,
    {
        rejectValue: BackendError
    }
>("devices/removeDevice", async (data, thunkApi) => {
    try {
        const { token, deviceId, macAddress } = data
        const request = await api({ token, method: 'delete', url: API_ENDPOINT, path: `/admin/devices/${deviceId}`, data: { mac: macAddress } })
        return (await request.data) as AdminRemoveDeviceData
    }
    catch (err) {
        return thunkApi.rejectWithValue((await err.response.data) as BackendError)
    }
})

interface UpdateDeviceAttributes {
    token: string
    location: string
    note: string
    deviceId: string
    userId: string
    support: boolean
}

interface UpdateDeviceData {
    message: string
}

export const updateDevice = createAsyncThunk<
    UpdateDeviceData,
    UpdateDeviceAttributes,
    {
        rejectValue: BackendError
    }
>("devices/updateDevice", async (data, thunkApi) => {
    try {
        const { token, location, note, deviceId, userId, support } = data
        const request = await api({
            token, method: 'put', url: API_ENDPOINT, path: `/users/${userId}/devices/${deviceId}`, 
            data: { location: location, note: note, support: support }
        })
        return (await request.data) as UpdateDeviceData
    }
    catch (err) {
        return thunkApi.rejectWithValue((await err.response.data) as BackendError)
    }
})