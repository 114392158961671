import React from "react"
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
import { store } from "./api/store"
import WebFont from 'webfontloader'
import './fontawesome'
import { Auth0Provider, AppState } from '@auth0/auth0-react'
import { AUTH_CONFIG } from "./auth0-variables"
import { App, history } from "./App"

WebFont.load({
    google: {
        families: ['Montserrat:400,700', 'sans-serif']
    }
})

const onRedirectCallback = (appState: AppState | undefined) => {
    // If using a Hash Router, you need to use window.history.replaceState to
    // remove the `code` and `state` query parameters from the callback url.
    // window.history.replaceState({}, document.title, window.location.pathname);
    history.replace((appState && appState.returnTo) || window.location.pathname)
}

const renderApp = () => {
    render(
        <Provider store={store}>
            <Auth0Provider
                domain={AUTH_CONFIG.domain}
                clientId={AUTH_CONFIG.clientId}
                redirectUri={window.location.origin}
                onRedirectCallback={onRedirectCallback}
                useRefreshTokens
                cacheLocation="localstorage"
            >
                <App />
            </Auth0Provider>
        </Provider>,
        document.getElementById('root')
    )
}

renderApp()
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
