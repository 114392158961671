import { IconDefinition, IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core"

export const faHome: IconDefinition = {
    prefix: 'fas' as IconPrefix,
    iconName: 'home' as IconName,
    icon: [
        576,
        512,
        [],
        '',
        'M490.6,512.3c10.2,0,18.3-8.2,18.3-18.3V306.5L298.2,132.9c-5.1-4.1-12.4-4.1-17.5,0l0,0L69.8,306.6v187.5\n' +
        '\tc0,10.2,8.2,18.3,18.3,18.3h402.5 M518.1,172.1L324.2,12.5c-20.3-16.7-49.4-16.7-69.8,0L9.6,212.5c-5.8,4.8-6.7,13.5-1.9,19.3l0,0\n' +
        '\tl29.1,35.4c4.8,5.8,13.5,6.7,19.3,1.9l0,0L280.6,86c5.1-4.1,12.4-4.1,17.5,0l223.2,184.4c5.8,4.8,14.5,4,19.3-1.9l0,0l29.1-35.4\n' +
        '\tc4.8-5.8,4-14.5-1.9-19.3l0,0l0,0L518.1,172.1'
    ]
}
