import { useAuth0 } from "@auth0/auth0-react"
import { ReactElement } from "react"

const Profile = (): ReactElement => {
    const {
        user
    } = useAuth0()

    return (
        <>
            {user?.email}
            <br />
            {user?.family_name}
            <br />
            {user?.given_name}
            <br />
            {user?.name}
            <br />
            {user?.nickname}
            <br />
            {user?.picture}
            <br />
            {user?.sub}
            <br />
            {user?.updated_at}
        </>
    )
}

export { Profile }
