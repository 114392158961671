import { ReactElement, useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { revealPassword } from "../api/device/thunk"
import { useAppDispatch } from "../api/store"
import styled from "styled-components"
import { Button, message } from "antd"
import { useAuth0 } from "@auth0/auth0-react"

interface Props {
    id: string
}

const StyledPasswordField = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    float: right;
`

const PasswordReveal = (props: Props): ReactElement => {
    const { id } = props

    const [password, setPassword] = useState('●●●●●●●●')
    const [isActive, setActive] = useState(false)
    const dispatch = useAppDispatch()
    const { getAccessTokenSilently } = useAuth0()
    const [token, setToken] = useState('')

    useEffect(() => {
        getAccessTokenSilently().then(response => setToken(response))
    }, [])  

    async function showPassword() {
        const request = await dispatch(revealPassword({ token: token, id: id }))
        if (revealPassword.fulfilled.match(request)) {
            setPassword(request.payload.message)
            setActive(true)
        }
    }

    async function copyPassword() {
        try {
            await navigator.clipboard.writeText(password)
            message.info("Copied password to clipboard")
        } catch (err) {
            message.error(err)
        }
    }

    return (
        <StyledPasswordField>
            <div>{password}</div>
            {!isActive && (
                <Button
                    type="link"
                    onClick={() => showPassword()}
                    style={{ alignSelf: "end" }}
                >
                    <StyledFontAwesomeIcon icon="eye"/>
                </Button>
            )}
            {isActive && (
                <Button
                    type="link"
                    onClick={() => copyPassword()}
                    style={{ alignSelf: "end" }}
                >
                    <StyledFontAwesomeIcon icon="copy"/>
                </Button>
            )}
        </StyledPasswordField>
    )
}

export { PasswordReveal }