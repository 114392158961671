import React, { ReactElement, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Layout } from '../../components/layout/Layout'
import { fetchAdminUsers } from "../../api/admin/thunk"
import moment from "moment"
import { Table, TableProps } from "antd"
import { RootState, useAppDispatch } from "../../api/store"
import { selectAllUsers } from "../../api/user/slice"
import { useTranslation } from "react-i18next"
import { useAuth0 } from "@auth0/auth0-react"
import { Spinner } from "src/components/Spinner"
import styled from "styled-components"

const StyledTable: any = styled(Table)`
  .ant-table-row:hover {
    cursor: pointer;
  }
`

const Users = (): ReactElement => {
    const dispatch = useAppDispatch()
    const users = useSelector(selectAllUsers)
    const [t, i18n] = useTranslation('admin')
    const { getAccessTokenSilently } = useAuth0()
    const isLoading = useSelector(
        (state: RootState) => state.users.isLoading
    )

    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(15)
    const size = useSelector(
        (state: RootState) => state.users.count
    )

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (a: any, b: any) => a.id - b.id
        },
        {
            title: 'First Name',
            dataIndex: 'first_name',
            key: 'first_name',
        },
        {
            title: 'Last Name',
            dataIndex: 'last_name',
            key: 'last_name',
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Devices claimed',
            dataIndex: 'devices_claimed',
            key: 'devices_claimed',
        },
        {
            title: 'Registration Date',
            dataIndex: 'registration_date',
            key: 'registration_date',
            render: (row: any) => (
                <>
                    {moment(row * 1000).fromNow()}
                </>
            ),
        },
    ]

    const onChange = (page: number, pageSize: number | undefined) => {
        setPage(page)
    }

    const onShowSizeChange = (current: number, pageSize: number) => {
        setPage(current)
        setPageSize(pageSize)
    }

    useEffect(() => {
        getAccessTokenSilently().then(token => dispatch(fetchAdminUsers({ token, page, pageSize })))
    }, [])

    return (
        <>
            {isLoading
                ? <Spinner />
                : <Layout
                    headerName={t('users')}
                >
                    <StyledTable
                        rowKey="id"
                        dataSource={users}
                        columns={columns}
                        scroll={{ x: 500 }}
                        pagination={{
                            pageSize: pageSize,
                            current: page,
                            total: size,
                            showSizeChanger: true,
                            pageSizeOptions: ['5', '10', '15', '20'],
                            onShowSizeChange,
                            onChange
                        }}
                    />
                </Layout>
            }
        </>
    )
}

export { Users }
