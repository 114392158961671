import React, { ReactElement, useState } from "react"
import { useLocation } from "react-router"
import queryString from "query-string"
import { Layout } from "../../components/layout/Layout"
import { Button, Checkbox, Col, Row, Modal, List, Divider, Input } from "antd"
import { useSelections } from "ahooks"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"

const StyledListItem = styled(List.Item)`
    color: #434968;
    font-size: 18px;
    .ant-list-item-meta-description {
        color: black;
        font-size: 17px;
        font-weight: medium;
    }
    .location {
        float: right;
        color: gray;
    }
`

const StyledLabel = styled.div`
  font-weight: bold;
  font-size: 16px;
`

const StyledInput = styled(Input)`
    margin-top: 5px;
`

const Provision = (): ReactElement => {

    const [visible, setVisible] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const { search } = useLocation()
    const { devices } = queryString.parse(search, { arrayFormat: 'comma' })

    function showModal() {
        setVisible(true)
    }

    function handleOk() {
        setConfirmLoading(true)
        setTimeout(() => {
            setVisible(false)
            setConfirmLoading(false)
        }, 2000)
    }

    function handleCancel() {
        setVisible(false)
    }

    const list = Array.isArray(devices) ? devices : [devices]
    const {
        selected,
        allSelected,
        isSelected,
        toggle,
        toggleAll,
        partiallySelected,
    } = useSelections(list, [])

    return (
        <>
            <Layout headerName="Provision">
                <div style={{ borderBottom: '1px solid #E9E9E9', padding: '10px 0' }}>
                    <Checkbox checked={allSelected} onClick={toggleAll} indeterminate={partiallySelected}>
                        Check all
                </Checkbox>
                </div>
                <Row style={{ padding: '10px 0' }}>
                    {list.map((o) => (
                        <Col span={12} key={o}>
                            <Checkbox checked={isSelected(o)} onClick={() => toggle(o)}>
                                {o}
                            </Checkbox>
                        </Col>
                    ))}
                </Row>
                <Button
                    htmlType="submit"
                    size="large"
                    type="primary"
                    onClick={showModal}
                >
                    Toevoegen
            </Button>
            </Layout>
            <Modal
                title="De volgende apparaten worden toegevoegd aan uw organisatie:"
                visible={visible}
                onOk={handleOk}
                okText="Toevoegen"
                cancelText="Annuleren"
                onCancel={handleCancel}
                confirmLoading={confirmLoading}
                transitionName=""
            >
                <List
                    size="small"
                    dataSource={selected}
                    renderItem={item =>
                        <StyledListItem>
                            <List.Item.Meta
                                // @ts-ignore */
                                avatar={<FontAwesomeIcon icon="device"/>}
                                description={<>{item}<span className="location">Elego</span></>}
                            />
                        </StyledListItem>}
                >
                </List>
                <Divider/>
                <StyledLabel>Communicatie ID</StyledLabel>
                <StyledInput></StyledInput>
            </Modal>
        </>
    )
}

export { Provision }