import { createSlice } from "@reduxjs/toolkit"
import { getSettings } from "./thunk"
import { Settings } from "./types"

interface SettingsState {
    isLoading: boolean
    data: Settings
}

const initialSettingsState: SettingsState = {
    data: {
        id: '',
        first_name: '',
        last_name: '',
        user_name: '',
        devices: {
            hardware: [
                {
                    component: '',
                    amount: 0,
                    versions: []
                }
            ],
            software: [
                {
                    component: '',
                    amount: 0,
                    versions: []
                }
            ]
        },
        organizations: [],
        settings: {
            current_organization: ''
        }
    },
    isLoading: false,
}

export const slice = createSlice({
    name: 'settings',
    initialState: initialSettingsState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getSettings.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
        })
        builder.addCase(getSettings.pending, (state, action) => {
            state.isLoading = true
        })
    }
})

const reducer = slice.reducer
export default reducer