import { Card, Col, Row } from "antd"
import React, { ReactElement } from "react"

const Subscriptions = (): ReactElement => {
    return (
        <>
            <Row gutter={16}>
                <Col span={8}>
                    <Card title="Free">
                        <li>Up to 3 devices</li>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="Premium">
                        <li>Up to 20 devices</li>
                        <li>Another feature</li>
                        <li>Another feature</li>
                        <li>Another feature</li>
                        <li>Another feature</li>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="Enterprise">
                        <li>Unlimited devices</li>
                        <li>Another feature</li>
                        <li>Another feature</li>
                        <li>Another feature</li>
                        <li>Another feature</li>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export { Subscriptions }
