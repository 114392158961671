import { combineReducers, configureStore } from "@reduxjs/toolkit"
import organizationsReducer from './organization/slice'
import devicesReducer from './device/slice'
import usersReducer from './user/slice'
import settingsReducer from './settings/slice'
import { useDispatch } from "react-redux"

const rootReducer = combineReducers({
    devices: devicesReducer,
    users: usersReducer,
    organizations: organizationsReducer,
    settings: settingsReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export type AppDispatch = typeof store.dispatch

export const useAppDispatch = (): any => useDispatch<AppDispatch>()

const store = configureStore({
    reducer: rootReducer
})

export { store }
