import React, { ReactElement, useEffect, useState } from 'react'
import { Table, Badge, Menu, Dropdown, Space, Button, message, TableProps } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { Device } from '../api/device/types'
import { provisionDevices } from "../api/organization/thunk"
import styled from 'styled-components'
import { useAppDispatch } from '../api/store'
import { useAuth0 } from '@auth0/auth0-react'

const StyledSellButton = styled(Button)`
    margin-bottom: 15px;
    float: left;
`

const StyledTable: any = styled(Table)`
  .ant-table-row:hover {
    cursor: pointer;
  }
`

interface ColumnDefinition {
    title: string
    dataIndex: string
    key: string
    visible: boolean
}

interface Props {
    columns: ColumnDefinition[]
    devices: Device[]
}

enum Status {
    ACTIVE = 'green',
    INACTIVE = 'red',
    UKNOWN = 'black',
    NOT_AVAILABLE = 'orange'
}

const menu = (mac: string) => (
    <Menu>
        <Menu.Item>Sell</Menu.Item>
        <Menu.Item>Install</Menu.Item>
        <Menu.Item onClick={() => {redirectToDevice(mac)}}>Manage</Menu.Item>
    </Menu>
)

function redirectToDevice(mac: string) {
    if (mac === undefined) {
        message.error('Device is not available!')
    } else {
        window.open(`https://${mac.split('-').join("")}.tunnel.portadial.nl`, "_blank")
    }
}

const OrganizationDeviceTable = (props: Props): ReactElement => {

    const { columns, devices } = props
    const [devicesToSell, setDevicesToSell] = useState<Device[]>([])
    const [selectionCount, setSelectionCount] = useState(0)
    const [token, setToken] = useState('')
    const dispatch = useAppDispatch()
    const { getAccessTokenSilently } = useAuth0()


    const getSoftwareVersionForDevice = (index: number) => {
        const obj = {
            software: devices.find(x => x.id === index)?.components.software.map(x => x)
        }
        return obj
    }

    const getHardwareVersionForDevice = (index: number) => {
        const obj = {
            hardware: devices.find(x => x.id === index)?.components.hardware.map(x => x)
        }
        return obj
    }

    const getStatus = (index: number) => {
        const obj = {
            tunnel_status: devices.find(x => x.id === index)?.tunnel_status
        }
        return obj
    }

    const rowSelection = {
        onChange: (_: React.Key[], selectedRows: Device[]) => {
            setDevicesToSell(selectedRows)
            setSelectionCount(selectedRows.length)
        }
    }

    useEffect(() => {
        getAccessTokenSilently().then(response => setToken(response))
    }, [])

    async function onSellClick() {
        if (devicesToSell.length === 0) {
            return
        }

        const deviceMacs = devicesToSell.map(x => x.mac)

        const data = await dispatch(provisionDevices({ token, organizationId: '1', devices: deviceMacs }))
        const pdf = new Blob([data.payload], { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(pdf)
        window.open(fileURL)
    }

    const expandedRowRender = (record: Device) => {
        const detailColumns = [
            {
                title: 'Software',
                dataIndex: 'software',
                key: 'software',
                render: (record: any) => (
                    record.map((x: any) => <p key={x.component}>{x.component} - v{x.version}</p>)
                )
            },
            {
                title: 'Hardware',
                dataIndex: 'hardware',
                key: 'hardware',
                render: (record: any) => (
                    record.map((x: any) => <p key={x.component}>{x.component} - v{x.version}</p>)
                )
            },
            {
                title: 'Status',
                dataIndex: 'tunnel_status',
                key: 'tunnel_status',
                render: (record: any) => (
                    <span>
                        <Badge color={mapStatus(record.status)} />
                        {record.ping_ms ? `${record.ping_ms} ms` : '0 ms'}
                    </span>
                ),
            },
            {
                title: 'Action',
                dataIndex: 'tunnel_status',
                key: 'operation',
                render: (record: any) => (
                    <Space size="middle">
                        <Dropdown overlay={menu(record.id)}>
                            <a>
                                More <DownOutlined />
                            </a>
                        </Dropdown>
                    </Space>
                ),
            },
        ]

        function mapStatus(status: string): Status {
            switch (status) {
                case 'active':
                    return Status.ACTIVE
                case 'inactive':
                    return Status.INACTIVE
                case 'unknown':
                    return Status.UKNOWN
                case 'not_available':
                    return Status.NOT_AVAILABLE
                default:
                    return Status.UKNOWN
            }
        }

        const data = [{
            ...getHardwareVersionForDevice(record.id),
            ...getSoftwareVersionForDevice(record.id),
            ...getStatus(record.id)
        }]

        return <StyledTable columns={detailColumns} dataSource={data} pagination={false} />
    }

    return (
        <>
            <StyledSellButton
                type="ghost"
                onClick={onSellClick}
            >
                ({selectionCount}) - Sell Selected
            </StyledSellButton>
            <StyledTable
                scroll={{ x: 500 }}
                columns={columns}
                expandable={{
                    expandedRowRender: (record: Device) => expandedRowRender(record)
                }}
                dataSource={devices}
                rowKey="id"
                rowSelection={rowSelection}
            />
        </>
    )
}

export { OrganizationDeviceTable }