import React, { PropsWithChildren, ReactNode } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "antd"
import { Link, LinkProps } from "react-router-dom"
import styled from "styled-components"
import { ButtonProps } from "antd/lib/button"
import { useTranslation } from "react-i18next"

const StyledLink: any = styled(Link)`
  font-weight: 400;
  color: #44337A;
  margin-left: 10px;
  font-size: 1.25em;
`

const StyledCreateButton: any = styled(Button)`
  margin-top: 5px;
  float: right;
`

const StyledHeader = styled.div`
  margin-bottom: 10px;
  p {
    font-weight: 700;
    font-size: 2.25em;
    display: inline;
  }
`

interface LayoutProps {
    children: ReactNode
    headerName?: string
    indexParameter?: string
    createParameter?: string
}

const CreateLink = (props: { parameter?: string }) => {
    const [t, i18n] = useTranslation('devices')

    if (props.parameter !== undefined) {
        return (
            <StyledLink to={props.parameter}>
                <StyledCreateButton type={"primary"} size={"large"}>{t('add')}
                </StyledCreateButton>
            </StyledLink>)
    } else return <></>
}

const ShowLink = (props: {parameter?: string}) => {
    if (props.parameter !== undefined) {
        return (
            <StyledLink to={props.parameter}>
                <p className="link">More <FontAwesomeIcon icon="arrow-right"/></p>
            </StyledLink>
        )
    } else return <></>
}

const Layout: React.FC<PropsWithChildren<LayoutProps>> = ({ headerName, createParameter, indexParameter, children }) => (
    <>
        <StyledHeader>
            <p>{headerName}</p>
            <ShowLink parameter={indexParameter}/>
            <CreateLink parameter={createParameter}/>
        </StyledHeader>
        {children}
    </>
)

export { Layout }
